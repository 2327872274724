<template>
  <div class="container">
    <h1>Home</h1>
  </div>
</template>

<script lang="ts">
export default {
  name: 'PageIndex'
}
</script>
